<template>
    <div>
        <a-spin :spinning="loading">
            <div class="ft20 cl-black cl-main ftw500">售后管理</div>

            <div class="mt20">
                <div class="form-search-box ">
                    <a-form layout="inline">
                        <a-form-item label="售后编号">
                            <a-input v-model="search.order_sn" placeholder="请输入售后编号"></a-input>
                        </a-form-item>

                        <a-form-item label="客户手机号">
                            <a-input v-model="search.mobile" placeholder="请输入客户手机号"></a-input>
                        </a-form-item>

                        <a-form-item>
                            <a-button @click="searchAct" type="primary">查询</a-button>
                            <a-button @click="cancelAct" class="ml10">取消</a-button>
                        </a-form-item>
                    </a-form>
                </div>
            </div>

            <div class="mt20">
                <div class="bg-w pd30" style="min-height: 600px;">
                    <div class="flex alcenter">

                        <div class="">
                            <a-radio-group v-model="search.status" button-style="solid" @change="changeStatus">
                                <a-radio-button :value="0">
                                    全部
                                </a-radio-button>

                                <a-radio-button :value="1">
                                    申请中
                                </a-radio-button>
                                <a-radio-button :value="2">
                                    已同意待退款
                                </a-radio-button>
                                <a-radio-button :value="3">
                                    已拒绝
                                </a-radio-button>
                                <a-radio-button :value="4">
                                    已退款
                                </a-radio-button>
                                <a-radio-button :value="-1">
                                    取消
                                </a-radio-button>
                            </a-radio-group>
                        </div>
                    </div>




                    <div class="mt30" v-if="datas.length>0">
                        <div class="mall-goods_order-item" v-for="(order,index) in datas">
                            <div class="mall-goods_order-item-header">
                                <div class="flex">
                                    <div style="width: 85%;" class="flex alcenter ">

                                        <div class="ml8 ">
                                            <div class="flex alcenter">
                                                <div class="flex alcenter">
                                                    <div class="ft14 ftw400 cl-info text-over4">订单编号：</div>
                                                    <div class="ft14 ftw600 cl-main text-over4">{{order.projectorder.order_sn}}</div>
                                                </div>
                                                <div class="flex alcenter">
                                                    <div class="ft14 ftw400 cl-info text-over4">售后编号：</div>
                                                    <div class="ft14 ftw600 cl-main text-over4">{{order.refund_sn}}</div>
                                                </div>

                                                <div class="flex alcenter ml40" v-if="order.member">
                                                    <div class="ft14 ftw400 cl-info text-over4">下单客户：</div>
                                                    <div class="ft14 ftw600 cl-main text-over4">
                                                        {{order.member.nick_name}} {{order.member.mobile}}</div>
                                                </div>



                                                <div class="flex alcenter ml40">
                                                    <div class="ft14 ftw400 cl-info text-over4">支付方式：</div>
                                                    <div class="ft14 ftw600 cl-main text-over4">{{order.projectorder.pay_type_text}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="width: 15%" class="flex center">操作</div>
                                </div>
                            </div>
                            <div>
                                <div class="flex">
                                    <div style="width: 40%;">
                                        <div>
                                            <div class="mall-goods_order-item-goods">
                                                <div class="flex space alcenter">
                                                    <div class="flex alcenter">
                                                        <img v-if="order.project_cover_img != null"
                                                             :src="order.project_cover_img"
                                                             style="width: 60px;height: 60px;" />

                                                        <div class="ml10">
                                                            <div class="ft14 ftw500 cl-info">

                                                                {{order.project_name}}
                                                            </div>
                                                            <div class="ft12 ftw400 cl-notice">{{order.project_sku_name}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="flex alcenter ft14 ftw500 cl-info">

                                                        <div class="ml8">x{{order.number}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="width: 60%;" class="flex">
                                        <div class="flex alcenter center"
                                             style="width: 25%;border-bottom: 1px solid #EBEDF5;border-right: 1px solid #EBEDF5;">
                                            <div class="ft12 ftw400 cl-info">
                                                <div class="mt5 text-center" v-if="order.refund_integral > 0">
                                                    积分退回：¥{{order.refund_integral}}</div>
                                                <div class="ft14 ftw500 cl-black mt5 text-center">
                                                    现金退回：¥{{order.refund_price}}</div>
                                            </div>
                                        </div>
                                        <div class="flex alcenter center"
                                             style="width: 25%;border-bottom: 1px solid #EBEDF5;border-right: 1px solid #EBEDF5;">
                                            <div class="ft12 ftw400 cl-info">
                                                <div>
                                                    <div class="text-center">售后时间</div>
                                                    <div class="mt4 text-center">{{order.create_time}}</div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="flex alcenter center"
                                             style="width: 25%;border-bottom: 1px solid #EBEDF5;border-right: 1px solid #EBEDF5;">
                                            <div class="ft14 ftw500 cl-info ">
                                                {{order.status_text}}<span  v-if="order.status==3">({{order.shop_reason}})</span>
                                            </div>
                                        </div>

                                        <div class="flex alcenter center"
                                             style="width: 25%;border-bottom: 1px solid #EBEDF5;border-right: 1px solid #EBEDF5;">
                                            <div class="ft14 ftw500 cl-info">
                                                <a-dropdown placement="bottomRight">
                                                  <span class="more-act">
                                                    <i class="iconfont iconmore_gray"></i>
                                                  </span>
                                                    <a-menu slot="overlay">
                                                        <a-menu-item>
                                                            <a class="menu-act" href="javascript:;" @click="see(index)">
                                                                <i class="iconfont ft14 iconedit"></i>
                                                                <span class="ml10">查看订单</span>
                                                            </a>
                                                        </a-menu-item>

                                                        <a-menu-item v-if="order.status==1">
                                                            <a class="menu-act" href="javascript:;" @click="okAct(index)">
                                                                <i class="iconfont ft14 icondelivery"></i>
                                                                <span class="ml10">同意申请</span>
                                                            </a>
                                                        </a-menu-item>
                                                      <a-menu-item v-if="order.status==2 || order.status==1" >
                                                        <a class="menu-act" href="javascript:;" @click="errAct(index)">
                                                          <i class="iconfont ft14 icondelivery"></i>
                                                          <span class="ml10">拒绝申请</span>
                                                        </a>
                                                      </a-menu-item>
                                                      <a-menu-item v-if="order.status==2 && order.pay_type=='weixin'">
                                                        <a class="menu-act" href="javascript:;" @click="refundAct(index)">
                                                          <i class="iconfont ft14 icondelivery"></i>
                                                          <span class="ml10">立即退款</span>
                                                        </a>
                                                      </a-menu-item>
                                                    </a-menu>
                                                </a-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="mt30 flex center" v-else>
                        <a-empty />
                    </div>

                    <div class="flex center mt20">
                        <a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current"
                                      :pageSize="pagination.pageSize" :show-total="pagination.showTotal" show-less-items />
                    </div>
                  <div v-if="cancelAfterVisible">
                    <cancel-after-order :after_id="after_id" :visible="cancelAfterVisible" @cancel="cancelCancelAfterOrder" @ok="okCancelAfterOrder"></cancel-after-order>
                  </div>
                </div>
            </div>
        </a-spin>
    </div>

</template>

<script>

    import {
        listMixin

    } from '../../common/mixin/list.js';
   import CancelAfterOrder from "./components/Index/modal/cancelAfterOrder.vue";
    export default {
      components: {CancelAfterOrder},
        mixins: [listMixin],
        data() {
            return {
                loading: false,
                cancelAfterVisible:false,
                after_id:0,
                pagination: {
                    current: 1,
                    pageSize: 5, //每页中显示10条数据
                    total: 0,
                    showTotal:function () {

                    },
                },
                search: {
                    order_sn: '',
                    status: 0,
                    mobile: '',
                },
                datas:[],
                deliver_num:0,
                write_off_num:0,
            }
        },
        methods: {
            getLists() {
                if (this.loading == true) return;
                this.loading = true;
                this.$http.api('admin/projectOrderAfter/afterLists', {
                    order_sn: this.search.order_sn,
                    status: this.search.status,
                    mobile: this.search.mobile,
                    limit: this.pagination.pageSize,
                    page: this.pagination.current,
                }).then(res => {
                    this.deliver_num=res.deliver_num;
                    this.pagination.total = res.total;
                    this.datas = res.list;
                    this.loading = false;
                    this.pagination.showTotal=(total)=>"共"+res.total+"条";
                }).catch(res => {
                    console.log(res);
                    this.loading = false;
                })
            },
          cancelCancelAfterOrder(){
            this.cancelAfterVisible=false;
          },
          okCancelAfterOrder(){
            this.cancelAfterVisible=false;
          },
            okAct(index){
                this.$confirm({
                    title:'确认同意这个售后申请？',
                    okText:"确定",
                    okType:"danger",
                    cancelText:"取消",
                    onOk : ()=>{
                        return new Promise((resolve,reject)=>{
                            this.$http.api('admin/projectOrderAfter/agree',{
                                after_id:this.datas[index].id,
                            }).then(res=>{
                                this.$message.success('已同意');
                                this.getLists();
                            }).catch(res=>{
                                console.log(res);
                            }).finally(()=>{
                                resolve();
                            })
                        })
                    }
                })
            },
          refundAct(index){
            this.$confirm({
              title:'确认退回用户现金:'+this.datas[index].refund_price+"元",
              okText:"确定",
              okType:"danger",
              cancelText:"取消",
              onOk : ()=>{
                return new Promise((resolve,reject)=>{
                  this.$http.api('admin/projectOrderAfter/refundprice',{
                    after_id:this.datas[index].id,
                  }).then(res=>{
                    this.$message.success('已同意');
                    this.getLists();
                  }).catch(res=>{
                    console.log(res);
                  }).finally(()=>{
                    resolve();
                  })
                })
              }
            })
          },
            errAct(index){
              this.after_id=this.datas[index].id;
              this.cancelAfterVisible=true;

            },
            changeStatus() {
                this.pagination.current = 1;
                this.getLists();
            },
            see(index) {
                this.$router.push('/project/projectAfterDetail?id=' + this.datas[index].id);
            },

            handleTableChange(pagination, filters, sorter) {
                this.pagination.current = pagination.current;
                this.getLists();
            },

        }
    }
</script>

<style>
    .mall-goods_order-item {
        width: 100%;
        margin-bottom: 20px;
    }

    .mall-goods_order-item-header {
        width: 100%;
        height: 50px;
        background: #F9F9F9;
        border: 1px solid #EBEDF5;
        line-height: 50px;
    }
  .status-coler{
    color: red;
  }
    .mall-goods_order-item-tag {
        padding: 1px 8px;
        border-radius: 2px;

        font-size: 12px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 16px;
    }

    .mall-goods_order-item-goods {
        padding: 30px 20px;
        border: 1px solid #EBEDF5;
        border-top: none;
    }

    .mall-goods_order-item-goods.tag {
        padding: 1px 4px;
        background: rgba(71, 114, 255, 0.1);
        border-radius: 2px;

        font-size: 12px;
        font-weight: 500;
        color: #4772FF;
    }

    .tag-num .position{
        position: absolute;
        top:0;
        left:0;
    }
</style>
